/* :root {
  --fc-today-bg-color: "white";
} */
/* override day number color and size */
.fc-day-number {
  font-size: 1.5em;
  color: #5c005c;
}

/* override day title color and size */
.fc-day-header {
  font-size: 1.5em;
  color: #00b294;
}

.fc-col-header {
  margin-bottom: 0;
}

.fc-toolbar-chunk {
  display: flex;
  align-items: center;
  gap: 10px;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 1.5em;
  display: flex;
  justify-content: space-between;
}

/* .fc-direction-ltr .fc-button-group > .fc-button{
  margin: 0 50px 0 0;
} */

.fc .fc-button-group {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.fc .fc-col-header-cell-cushion {
  display: inline-block;
  padding: 2px 4px;
  color: #51b15c;
}

.fc .fc-daygrid-day-number {
  padding: 4px;
  position: relative;
  z-index: 4;
  color: black;
}

th .fc-scrollgrid-sync-inner {
  height: 32px;
  background-color: #51b15c1a;
  text-align: center;
}

.fc-direction-ltr .fc-daygrid-event .fc-event-time {
  margin-right: 3px;
  color: green;
}

.fc-daygrid-dot-event .fc-event-title {
  flex-grow: 1;
  flex-shrink: 1;
  font-weight: 700;
  min-width: 0px;
  overflow: hidden;
  color: green;
}

/* .event-passed {
  background-color: red;
}

.event-in-progress {
  background-color: green;
}

.event-upcoming {
  background-color: blue;
} */

.fc .fc-button-primary {
  background-color: transparent;
  border-color: transparent;
  color: green;
}

.fc-h-event .fc-event-title-container {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0px;
  background: none;
  padding: 5px 2px;
  height: auto;
  color: rgba(81, 177, 92, 1);
}

.fc-h-event .fc-event-title-container:hover {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0px;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 5px 2px;
  height: auto;
}
.fc-day-today .fc-daygrid-day-number {
  font-weight: 700;
  color: lightblue !important;
}

.fc-addEvent-button {
  background-color: #51b15c !important;
  color: white !important;
}

.fc-holiday {
  background-color: #ff8c8c !important;
  font-weight: 600;
  display: flex;
  color: white;
  justify-content: space-between;
  align-items: center;
}

[role="gridcell"]:has(.fc-holiday) {
  background-color: #ffe8e8 !important;
}

.fc-holiday .fc-daygrid-day-number {
  color: white;
}

.fc-more-link {
  color: black;
}
.fc .fc-popover {
  z-index: 1299;
}
.fc-h-event {
  border: none;
}

[role="gridcell"].fc-day-sat {
  background-color: #f5f5f5;
}

[role="gridcell"].fc-day-sun {
  background-color: #f5f5f5;
}

[role="row"]:has(td) {
  height: 182px;
}
.fc-event-main {
  padding: 4px 10px;
}
.fc-more-popover .fc-event-main {
  margin-bottom: 5px;
  max-width: 100px;
}

.hidden {
  display: none;
}
