.tooltip-simple > .graph-tooltip-content {
  border-bottom: 1px solid rgb(194, 194, 194);
  padding: 0px 12px;
}

.tooltip-simple > .graph-tooltip-content:last-child {
  border-bottom: 0px;
}

.tooltip-simple > .graph-tooltip-content > .title-tooltip {
  color: rgb(85, 85, 85);
  font-weight: 600;
  font-size: 14px;
}

.tooltip-simple > .graph-tooltip-content > .desc-tooltip {
  color: rgb(131, 128, 128);
  font-size: 12px;
}
