body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #f9f9f9;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #51b15c;
  opacity: 0.5;
  border-radius: 2px;
}

::-webkit-any-link {
  cursor: pointer;
  text-decoration: none;
}

a {
  text-decoration: none;
  color: rgba(81, 177, 92, 0.06);
}

th {
  box-sizing: border-box;
  padding: 12px 10px;
  border: 0.3px solid #c8c8cc;
}

td {
  box-sizing: border-box;
  padding: 12px 10px;
  border: 0.3px solid #c8c8cc;
}

.inactive {
  color: #c8c8cc;
}

.prev-month {
  color: #c8c8cc;
}

@media screen and (max-width: 600px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }
  /* mengatur tampilan untuk header kolom */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  td {
    /* mengatur tampilan untuk isi kolom */
    border: none;
    position: relative;
    padding-left: 50%;
  }
  td:before {
    /* menambahkan label pada isi kolom */
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    content: attr(data-column);
    font-weight: bold;
  }
}

table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  border-collapse: collapse;
}

thead {
  display: none;
}

@media (min-width: 768px) {
  table {
    width: auto;
    max-width: none;
  }

  thead {
    display: table-header-group;
  }
}

th {
  text-align: left;
}

td,
th {
  padding: 0.5rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table-container {
  overflow: auto;
  height: 400px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

#chartjs-tooltip {
  background: #ffffff;
  padding: 8px 10px !important;
  width: 150px !important;
  border: 0.2px solid #c8c8cc;
  border-radius: 10%;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}

#chart-box {
  background: #f9f9f9;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px;
  padding: 0px;
}

#chart-label {
  margin: 0px;
  padding: 0px;
  margin-left: 10px !important;
}

#title {
  margin: 0px;
  padding: 0px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 130%;
  color: #171717;
}

#date {
  margin: 0px;
  padding: 0px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 130%;
  color: #939393;
}
