.tooltip-img > .graph-tooltip-content {
  border-bottom: 1px solid rgb(194, 194, 194);
  padding: 0px 12px;
  display: grid;
  grid-template-columns: 60px 1fr;
}

.tooltip-img > .graph-tooltip-content > .content-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tooltip-img > .graph-tooltip-content > .content-img > .img {
  width: 50px;
  border-radius: 50%;
}

.tooltip-img > .graph-tooltip-content:last-child {
  border-bottom: 0px;
}

.tooltip-img > .graph-tooltip-content > .content-text > .title-tooltip {
  color: rgb(85, 85, 85);
  font-weight: 600;
  font-size: 14px;
}

.tooltip-img > .graph-tooltip-content > .content-text > .desc-tooltip {
  color: rgb(131, 128, 128);
  font-size: 12px;
}
